import { Observable } from "rxjs";
import { Joint } from "src/app/domain/models/joint";
import { Project } from "src/app/domain/models/project";

export abstract class IProjectService {
    abstract getAllProjects(): Observable<Project[]>;
    abstract getProjectDetail(projectId: string): Observable<Project | undefined>;
    abstract getProjectJoints(projectId: string): Observable<Joint[]>;
    abstract getProjectDrawings(project: Project): Observable<string[]>;
    abstract deleteProjectDrawing(drawing: string): Observable<boolean>;
}